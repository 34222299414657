*{
  box-sizing: border-box;
  margin:0;
  padding:0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;    
}
body{
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
html{
  --headerHeight:70px;
  --footerHeight:80px;
  --blue:#0091fa;
  --red:#de3500;
  --gray-light:#f4f6f7;
  --gray:#b4b4b9;
  --white:#ffffff;
  --black:#000000;
  --gray-dark:#636363;
  --tmp_img: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=)
  
  
  
}
.landscape{
  --footerHeight:100px;
}

.App, #threejs{
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 100%;
  z-index: 1;
  background-color: white;
}

html, body, .App, #threejs {  
  overflow: hidden;
  height:100%;

  font-family: 'NA1', 'Times New Roman', Times, serif;
  font-display: swap;
}
body {
  min-height: 100%;
  min-height: stretch;
  min-height: stretch;
 
}
html {
  height: stretch;
  
}

#root{
  height: 100%;
}
.App{
  z-index: 2;
  background-color: transparent;
  pointer-events: none;
  height:100%;
  max-height: stretch;  
}

#threejs{
  
}
#threejs canvas{
  background-color: white;
}
#threejs.half{
  width:50%;
  transition: width .5s;
}

.version{
  position: absolute;
  background-color: var(--blue);
  z-index: 100;
  color:var(--white);
  padding:20px;
  top:0%;
  left:50%;
  transform: translate(-50%, 0%);  
  opacity: 0;
  animation: versionAnim 3s linear 0s 1 normal forwards;
  pointer-events: none;
}

@keyframes versionAnim {
  0% {opacity: 0; }
  5% {opacity: 1; }
  85% {opacity: 1; }
  100% {  opacity: 0;}
}