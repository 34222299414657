.PageView {
  position: relative;
  opacity: 0;
  pointer-events:none;
  display: flex;
  justify-content: stretch;
  top:0;

  transition:opacity 1s;
}

.PageView pre, .PageView code{
  display:none;
}
.PageView.opened {
  opacity: 1;

  pointer-events: none;
  transition:opacity 0.5s;
}

.icon{
  position: absolute;
  top:5px;
  right:10px;
  cursor: pointer;
  pointer-events:all;
  color:transparent;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height:40px;
  width:40px;
  z-index: 2;
}

.icon>span{
  display: none;
}
.icon>div{
  --mask:var(--tmp_img);
  flex: 1 1 auto;
  background-color: var(--blue);
  color:transparent;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-image:var(--mask);
  margin:10px;
}

.close>div{
  background-color: var(--red);
}

.icon.left, .icon.right{
  top:calc(50% - 30px);
}

.icon.left{
  right:unset;
  left:20px;
}
.icon.right{
  right:20px;
}


.content{
  position: relative;
  flex:1 1 auto;



}

:global(.landscape) .content.hasContent{
  position: absolute;
  right:0;
  height:100%;
}

:global(.landscape) .content.showContent{
  width: 50%;
}

.overlays{
  pointer-events: none;
  position: absolute;
  width:100%;
  height:100%;
}

.overlays>*{
  pointer-events: all;
}
:global(.landscape) .content.hasContent .overlays{
  left:-100%;
}

.externalContent{
  background-color: var(--white);
  min-height: 100%;

}

.externalContent>*{
  animation: fadeInFromNone 0.5s ease-out;
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

.scroll{
  pointer-events: all;
  overflow: auto;
  height: 100%;
  width:100%;

  transform: translateX(100%);
  transition: transform 0.5s;
}


.opened .scroll{
  transform: translateX(0%);
}

.mainTitle{
  position: absolute;

  bottom:20px;
  right:20px;
  opacity: 0;
}

.opened .mainTitle{
  opacity: 1;
  transition: .5s;
  background-color: var(--white);
}

.mainTitle>*{
  padding:5px 15px;
  animation: fadeInFromNone 0.5s ease-out;
}
.mainTitle .h1{
  background-color: var(--white);
  color:var(--blue);
  font-size: 200%;
  font-weight: bold;
  display: flex;
  padding-left:0;
}

:global(.landscape) .mainTitle .h1{

  font-size: 300%;

}

.mainTitle h2{
  background-color: var(--blue);
  color:var(--white);
  font-size:100%;
  font-weight: normal;
}



.page_icon{
  --size:50px;
  height:var(--size);
  width:var(--size);
  background-color: transparent;
  overflow: visible;


}

:global(.landscape) .page_icon{
  --size:70px;

}
.page_icon>div{
  --customScale:100%;
  --mask:var(--tmp_img);
  flex:1 1 auto;
  text-align: center;
  cursor: pointer;
  background-color: var(--blue);
  color:transparent;
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-image:var(--mask);
  mask-position: center;
  height:100%;
  transform:scale(var(--customScale));
  transition: 0.5s background-color;
}
