.Header {
  height:var(--headerHeight);
  background-color: var(--white);
  display: flex;
  align-items: center;
  transform:translateY(-100%);
}

:global(.introDone) .Header {
  transform:translateY(0);
  transition:transform 1s;
}

.logo{
  height: 45%;
  margin-left: 10px;
  cursor: pointer;
  pointer-events: all;
}