body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#preloader{
  position: absolute;
  background-color: white;
  color: var(--blue);
  top:0;
  left:0;
  height:100%;
  width:100%;
  z-index: 2;
}

/*https://cssloaders.github.io/*/
#preloader.loaded{
  opacity: 0;
  transition: opacity .5s;
}

#preloader #preloader-value{
  position: absolute;
  
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);  
  text-align: center;
}

#preloader  .spinner {
  position: absolute;
  top:52%;
  left:50%;
  
  width:  48px;
  height: 48px;
  background: rgba(255, 255, 255, .5);
  transform: translate(-50%, 50%) rotateX(65deg) rotate(45deg);
  
  /*transform: perspective(200px) rotateX(65deg) rotate(45deg); */
  color: var(--red);
  
  animation: layers1 1s linear infinite alternate;
}

#preloader  .spinner.loaded{
  opacity: 0;
  transition: opacity 1s;
}
#preloader .spinner:after {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--blue);
  animation: layerTr 1s linear infinite alternate;
  
}

@keyframes layers1 {
  0% { box-shadow: 0px 0px 0 0px  }
 90% , 100% { box-shadow: 20px 20px 0 -4px  }
}
@keyframes layerTr {
  0% { transform:  translate(0, 0) scale(1) }
  100% {  transform: translate(-25px, -25px) scale(1) }
}