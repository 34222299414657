.NetApp{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 2;
  
  display:flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.NetApp>*{
  flex: 0 0 auto;
}

.NetApp>*:nth-child(3){
  /*page view*/
  flex:1 1 auto;
  max-height: calc(100% - var(--footerHeight) - var(--headerHeight));
}
