.Footer {
  height:var(--footerHeight);
  background-color: var(--white);
  pointer-events: all;
  position: relative;
  width: 100%;
  transform:translateY(100%);    
}


:global(.introDone) .Footer {
  transform:translateY(0);
  transition:transform 1s;
}


.info_icons{
 display: none;
 
}

:global(.landscape) .info_icons{
  position: absolute;
  bottom:20px;
  left:30px;
  display: flex;
  font-size: 85%;

  gap:10px;
  color:var(--gray-dark); 
}

.info_icons>*{
  pointer-events: all;
  cursor: pointer;
}
.info_icons>*:hover{
  color:var(--blue);
  transition: color 0.5s;
}
 
.icons{
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height:100%;
  
}

:global(.landscape) .icons{  
  width: 40%;
  min-width: calc(var(--footerHeight) * 4);
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  background-color: var(--gray-light);
}

.icons>*{
  flex:1 1 auto;
  text-align: center;
  cursor: pointer;
  background-color: var(--white);
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s background-color;
  
}

:global(.landscape) .icons>*{
  background-color: var(--gray-light);
}
.icons>*>span{
  position: absolute;
  color:transparent
}
.icons>*.active{
  background-color: var(--blue);
}

.icons>*>div{
  --customScale:100%;
  --mask:var(--tmp_img);
  flex:1 1 auto;
  text-align: center;
  cursor: pointer;
  background-color: var(--blue);
  color:transparent;
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-image:var(--mask);
  mask-position: center;
  height:100%;  
  transform:scale(var(--customScale));
  transition: 0.5s background-color;
}

:global(.landscape) .icons>*>div{
  transform:scale(calc(var(--customScale) * 0.9));
}

.icons>*.active>div{
  background-color: var(--white);
}

.tmp{
  position: absolute;
  opacity: .5;
}