.Drawer {
  
  position: absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  pointer-events: none;
  z-index: 10;
}

.container, .icon{
  position: absolute;
  top:0;
  right:10px;
  cursor: pointer;
  pointer-events: all;
}

.icon{
  --height: 45px;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height:var(--height);
  width:var(--height);
  top:calc((var(--headerHeight) - var(--height)) / 2);
  opacity:0;
  pointer-events: none;
}




:global(.introDone) .icon {
  
  pointer-events: all;
  opacity: 1;
  
  transition:opacity 1s 0.7s;
}


.icon>span{
  display: none;
}
.icon>div{
  --mask:var(--tmp_img);
  flex: 1 1 auto;
  background-color: var(--blue);
  color:transparent;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-image:var(--mask);
  margin:10px;
}

.icon.contact{
  right:60px;
  width:35px;
}
:global(.landscape) .icon.contact{
  right:70px;  
}

.icon.contact>div{
  background-color: var(--black);
  margin: 10px 0;
}

.container{
  cursor: default;
  right:0;
  left:0;
  top:var(--headerHeight);
  height:0;
  background-color: var(--white);
  color:var(--white);
  transition:0.5s height, 0.5s background-color, 0.5s padding;
  padding:0 0px;
}

.opened .container{
  height:calc(100% - var(--headerHeight)); 
  overflow: auto;
  background-color: var(--blue);
  padding:30px 0px;
  
}

.container{
  display:flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
}
.main{
  display:flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap:5px;
}

.container>*>*.active, .container>*>*:hover{
  

}
.main>*{
  font-size: 150%;
  font-weight: bold;
  padding:5px 0 5px 50px;
  cursor: pointer;
  pointer-events: all;
  display:flex;
  align-items: center;
  gap:0px;
}

:global(.landscape) .container{
  justify-content: center;
  align-items:center;
}
:global(.landscape) .main{
  gap:20px;
}
:global(.landscape) .main>*{
  padding:5px 50px 5px 0px;
  font-size: 250%;
}

.main>div:hover, .main>div.active{
  background-color: var(--white);
  transition: background-color 0.5s, color 0.5s;
  color:var(--blue);
}
.footer, .footer_2{
  display:flex;
  gap:20px;
  flex-direction: row;
  padding-left:  50px;  
}

.footer_2{
  font-weight: bold;
}


.footer>*, .footer_2>*{
  padding:20px 0;
  cursor: pointer;
}


:global(.landscape) .footer, :global(.landscape) .footer_2{
  display:none;
}


.page_icon{
  --size:60px;
  height:var(--size);
  width:var(--size);
  background-color: transparent;
  
}

:global(.landscape) .page_icon{
  --size:100px;
}
.page_icon>div{
  --customScale:100%;
  --mask:var(--tmp_img);
  flex:1 1 auto;
  text-align: center;
  cursor: pointer;
  background-color: var(--white);
  color:transparent;
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-image:var(--mask);
  mask-position: center;
  height:100%;  
  transform:scale(var(--customScale));
  transition: 0.5s background-color;
}

.main>div:hover .page_icon>div, .main>div.active .page_icon>div{
  background-color: var(--blue);
}